<template>
  <base-material-card
    icon="mdi-map-marker"
    color="secondary"
    title="Address"
    class="address"
  >
    <v-row
      justify="center"
      align="center"
      class="mb-3"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            small
            color="accent"
            @click="addAddressDlg = true"
            v-on="on"
          >
            <v-icon>
              mdi-map-marker-plus
            </v-icon>
          </v-btn>
        </template>
        Add Address
      </v-tooltip>
    </v-row>

    <v-expansion-panels v-if="person.addresses && person.addresses.length">
      <v-expansion-panel
        v-for="address in person.addresses"
        :key="address.id"
        @change="reloadMap(address.id)"
      >
        <v-expansion-panel-header>
          {{ addressName(address) }}
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-form
            :ref="`addressForm${address.id}`"
            lazy-validation
            @submit.prevent="updateAddress(address)"
          >
            <v-container class="py-0">
              <l-map
                v-if="address.latitude && address.longitude"
                :ref="`map${address.id}`"
                class="map"
                :zoom="13"
                :center="[Number(address.latitude), Number(address.longitude)]"
                :options="{ dragging: false, scrollWheelZoom: false}"
              >
                <l-tile-layer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                <l-marker :lat-lng="[Number(address.latitude), Number(address.longitude)]" />
              </l-map>

              <v-row>
                <v-col
                  v-for="(field, i) in fields"
                  :key="i"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-if="field.model === 'country'"
                    v-model="address[field.model]"
                    :prepend-icon="field.icon"
                    :items="mixinItems.countries"
                    item-text="name"
                    item-value="code"
                    :loading="loadingMixins.countries"
                    :label="field.label"
                    :rules="field.required ? [required] : []"
                  >
                    <template
                      v-if="address[field.model]"
                      v-slot:prepend
                    >
                      <flag
                        :iso="address[field.model]"
                        :squared="false"
                      />
                    </template>
                    <template
                      v-else
                      v-slot:prepend
                    >
                      <v-icon>mdi-flag</v-icon>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    v-else
                    v-model="address[field.model]"
                    :prepend-icon="field.icon"
                    :label="field.label"
                    :type="field.type"
                    :readonly="field.readonly"
                    :rules="field.required && !['latitude', 'longitude'].includes(field.model) ? [required] : []"
                  />
                </v-col>
              </v-row>
              <div class="pa-3 text-right">
                <v-btn
                  color="success"
                  type="submit"
                  :loading="updating"
                >
                  Update
                </v-btn>
                <v-btn
                  class="ml-3"
                  color="error"
                  :loading="deleting"
                  @click="deleteAddress(address)"
                >
                  Delete
                </v-btn>
              </div>
            </v-container>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <p v-else>
      No Addresses
    </p>
    <v-dialog
      v-model="addAddressDlg"
      max-width="600"
    >
      <v-form
        ref="newAddressForm"
        lazy-validation
        @submit.prevent="addAddress"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add Address</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="(field, i) in fields"
                :key="i"
                cols="12"
                md="6"
              >
                <template v-if="!field.readonly">
                  <v-autocomplete
                    v-if="field.model === 'country'"
                    v-model="newAddress[field.model]"
                    :prepend-icon="field.icon"
                    :items="mixinItems.countries"
                    item-text="name"
                    item-value="code"
                    :loading="loadingMixins.countries"
                    :label="field.label"
                    :rules="field.required ? [required] : []"
                  >
                    <template
                      v-if="newAddress[field.model]"
                      v-slot:prepend
                    >
                      <flag
                        :iso="newAddress[field.model]"
                        :squared="false"
                      />
                    </template>
                    <template
                      v-else
                      v-slot:prepend
                    >
                      <v-icon>mdi-flag</v-icon>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    v-else
                    v-model="newAddress[field.model]"
                    :prepend-icon="field.icon"
                    :label="field.label"
                    :type="field.type"
                    :rules="field.required ? [required] : []"
                  />
                </template>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="mr-2"
                  color="success"
                  type="submit"
                  :loading="adding"
                >
                  Add
                </v-btn>
                <v-btn
                  color="error"
                  type="button"
                  @click="addAddressDlg = false"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </base-material-card>
</template>

<script>
  import { mapState } from 'vuex'
  import { Api } from '@/apis'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'
  import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

  export default {
    components: {
      LMap, LTileLayer, LMarker,
    },

    mixins: [
      fetchInitials([
        MIXINS.countries,
      ]),
    ],

    props: {
      from: {
        default: '',
        type: String,
      },
    },

    data: () => ({
      required: value => !!value || 'This field is required.',
      fields: [
        { label: 'Location Name', model: 'location_name', icon: 'mdi-map-marker' },
        { label: 'Street 1 *', model: 'street_1', icon: 'mdi-map-marker', required: true },
        { label: 'Street 2 *', model: 'street_2', icon: 'mdi-map-marker' },
        { label: 'State *', model: 'state', icon: 'mdi-map-marker', required: true },
        { label: 'City *', model: 'city', icon: 'mdi-map-marker', required: true },
        { label: 'Zip *', model: 'zip', icon: 'mdi-map-marker', required: true },
        { label: 'Country *', model: 'country', icon: 'mdi-flag', required: true },
        { label: 'Latitude', model: 'latitude', icon: 'mdi-latitude', required: true, readonly: true },
        { label: 'Longitude', model: 'longitude', icon: 'mdi-longitude', required: true, readonly: true },
      ],
      updating: false,
      adding: false,
      deleting: false,
      addAddressDlg: false,
      newAddress: {
        country: 'US',
      },
    }),

    computed: {
      ...mapState({
        customer: state => state.items.customer,
        agent: state => state.items.agent,
        companyClass: state => state.items.companyClass,
      }),

      person () {
        switch (this.from) {
          case 'agent':
            return this.agent
          case 'companyClass':
            return this.companyClass
          default:
            return this.customer
        }
      },
    },

    methods: {
      async addAddress () {
        if (this.$refs.newAddressForm.validate()) {
          this.adding = true
          await new Api().post({
            url: 'customer/address',
            request: {
              ...this.newAddress,
              id: this.person.id,
              user: this.person.user,
            },
          })
          this.adding = false
          this.addAddressDlg = false
          // Storeupdate Customer
        }
      },

      async updateAddress (address) {
        if (this.$refs[`addressForm${address.id}`][0].validate()) {
          this.updating = true
          await new Api().put({ url: 'customer/address/' + address.id, request: address })
          this.updating = false
          // Storeupdate Customer
        }
      },

      async deleteAddress (address) {
        const decision = await this.$confirm('Are you sure you want to delete?', { title: 'Warning' })
        if (decision) {
          this.deleting = true
          await new Api().delete('customer/address/' + address.id)
          this.deleting = false
          // Storeupdate Customer
        }
      },

      addressName (address) {
        const texts = []
        for (const [key, value] of Object.entries(address)) {
          if (
            [
              'street_1',
              'city',
              'state',
              'zip',
            ].includes(key) && value
          ) {
            texts.push(value)
          }
        }
        return texts.join(', ')
      },

      reloadMap (id) {
        setTimeout(() => this.$refs[`map${id}`][0].mapObject.invalidateSize(), 500)
      },
    },
  }
</script>
